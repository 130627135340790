// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-your-impact-js": () => import("./../../../src/pages/make-your-impact.js" /* webpackChunkName: "component---src-pages-make-your-impact-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-covid-19-js": () => import("./../../../src/templates/covid19.js" /* webpackChunkName: "component---src-templates-covid-19-js" */),
  "component---src-templates-empoweryourself-js": () => import("./../../../src/templates/empoweryourself.js" /* webpackChunkName: "component---src-templates-empoweryourself-js" */),
  "component---src-templates-healyourself-js": () => import("./../../../src/templates/healyourself.js" /* webpackChunkName: "component---src-templates-healyourself-js" */),
  "component---src-templates-healyourworld-js": () => import("./../../../src/templates/healyourworld.js" /* webpackChunkName: "component---src-templates-healyourworld-js" */),
  "component---src-templates-project-single-js": () => import("./../../../src/templates/project-single.js" /* webpackChunkName: "component---src-templates-project-single-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-read-single-js": () => import("./../../../src/templates/read-single.js" /* webpackChunkName: "component---src-templates-read-single-js" */),
  "component---src-templates-video-page-js": () => import("./../../../src/templates/video-page.js" /* webpackChunkName: "component---src-templates-video-page-js" */)
}

